import { AboutUsCarousel } from "./AboutUsCarousel"

function AboutUs() {
    return (
        <div className="mt-28 mx-9 lg:mx-20 xl:mx-48 2xl:mx-72">
            <h1 className="text-3xl md:text-5xl text-center ">Our Studio</h1>
            <div className="mt-16 py-8">
                <h1 className="text-2xl md:text-3xl capitalize font-thin">
                    We believe software is a form of art
                </h1>
                <div className="text-xl font-extralight mt-8 leading-relaxed">
                    At Joe's Studio, our mission is to be the vanguards of innovation, constantly pushing the boundaries of web design, and reshaping the online landscape one masterpiece at a time. We are not merely designers and developers; we are dream weavers who believe in the power of digital alchemy to transform a brand's identity into something extraordinary, something that resonates at a profound level with its audience.

                    Aspiring to revolutionize the online space, we are relentless in our pursuit of perfection, tirelessly refining our craft to weave together websites that transcend the ordinary, enchant the senses, and beckon visitors to embark on an unforgettable journey with the brand.<br></br> <br></br>For us, a website is not just a mere collection of web pages; it is an experiential voyage, an immersion into the heart and soul of a brand, where its narrative unfolds and captivates the world.

                    Join us at Joe's Studio as we embark on this thrilling odyssey of transforming brands into digital pioneers, making a lasting impact on the world, and crafting websites that stand as eternal symbols of excellence, innovation, and boundless aspiration. Together, let us breathe life into your brand's online expression and etch its legacy in the ever-evolving tapestry of the digital age.
                </div>
            </div>
            <div className="mt-16 border-white p-0 md:py-8 text-center">
                <h1 className="text-3xl capitalize font-thin mb-8">
                    Reviews
                </h1>
                <AboutUsCarousel></AboutUsCarousel>
            </div>
        </div>
    )
}

export default AboutUs