import reacticon from '../../Media/react.png'
import shopifyicon from '../../Media/shopify.png'
import squarespaceicon from '../../Media/squarespace.png'
import nodejsicon from '../../Media/nodejs.png'
import fivestars from '../../Media/5stars.png'
import TechBox from './TechBox'
import Footer from '../../Misc/Footer'

function StatementSection() {
    return (
        <div className="section">
            <div className='min-h-screen overflow-x-hidden flex justify-center text-center'>
                <div>
                    <p className="2xl:mt-56 mb-20 mt-40  text-white capitalize text-xl md:text-3xl " >focus on what you do best<br></br>Leave design and software to the pros</p>
                    <div className="flex flex-row  justify-center gap-1 lg:gap-4 mb-40 lg:mb-20">
                        <TechBox src={shopifyicon} alt="Shopify"></TechBox>
                        <TechBox src={squarespaceicon} alt="Square Space"></TechBox>
                        <TechBox src={reacticon} alt="React"></TechBox>
                        <TechBox src={nodejsicon} alt="Node JS"></TechBox>
                    </div>
                    <button type="button" className=" text-white p-1  sm:px-3 sm:py-2 md:text-lg transition border-2 border-white hover:bg-gray-900 px-3 py-2">Join more than 300 4.7 <img src={fivestars} className="h-4 inline-block ml-0 mr-1 mb-1" /> customers</button>
                </div>
            </div>
        </div>
    )
}

export default StatementSection