import logo from '../Media/logo.svg';

function Navbar() {
    return (
        <div class="mt-0 fixed top-0 z-[999] w-screen border-b border-gray-950 backdrop-blur-md">
            <header class="text-2xl">
                <div
                    class="mx-auto flex h-14 md:h-20 items-center gap-5 px-5"
                >
                    <a class="block text-black-600" href="/">
                        <span class="sr-only">Home</span>
                        <img src={logo} alt="logo" class="h-14 md:h-20 invert"/>
                    </a>

                    <div class="flex flex-1 items-center justify-end md:justify-between">
                        <nav aria-label="Global" class=" md:block capitalize">
                            <ul class="flex items-center gap-4 md:gap-6 text-sm md:text-lg text-white">
                                <li>
                                    <a href="/" class="hidden md:block opacity-75 hover:opacity-100 transition duration-300">
                                        Home
                                    </a>
                                </li>

                                <li>
                                    <a href="/packages" class=" opacity-75 hover:opacity-100 transition duration-300">
                                        Packages
                                    </a>
                                </li>
                                <li>
                                    <a href="/about" class="  opacity-75 hover:opacity-100 transition duration-300">
                                        Our Studio
                                    </a>
                                </li>
                                <li>
                                    <a href="/contact" class="text-base md:hidden text-red-500 hover:opacity-100 transition duration-300">
                                        50% OFF
                                    </a>
                                </li>
                            </ul>
                        </nav>

                        <div class="items-center gap-4 ">
                            <div class="hidden md:flex">
                                <a
                                    class="text-white text-center px-3 py-2 text-lg font-normal transition bg-red-700"
                                    href="/contact"
                                >
                                    AUGUST SALES:
                                    50% OFF !!!
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Navbar