import { useEffect, useState } from "react"
import Cookies from 'js-cookie';
import ClientReview from "../AboutUs/ClientReview";
import firestore from "../Firebase/config";

function Packages() {
    const [btnnum, setBtnnum] = useState(Cookies.get('package') || 1);

    useEffect(() => {
        if (btnnum == 0) {
            document.getElementsByClassName("0")[0].classList.add("bg-gray-700");
            document.getElementsByClassName("1")[0].classList.remove("bg-gray-700");
            document.getElementsByClassName("2")[0].classList.remove("bg-gray-700");
        }
        else if (btnnum == 1) {
            document.getElementsByClassName("0")[0].classList.remove("bg-gray-700");
            document.getElementsByClassName("1")[0].classList.add("bg-gray-700");
            document.getElementsByClassName("2")[0].classList.remove("bg-gray-700");
        }
        else if (btnnum == 2) {
            document.getElementsByClassName("0")[0].classList.remove("bg-gray-700");
            document.getElementsByClassName("1")[0].classList.remove("bg-gray-700");
            document.getElementsByClassName("2")[0].classList.add("bg-gray-700");
        }
    }, [btnnum])

    function handleToggle(num) {
        setBtnnum(num);
        Cookies.set('package', num, { expires: 7 });
    }

    return (
        <div className="mt-20 mx-9 lg:mx-20 xl:mx-48 2xl:mx-72 text-center">
            <header className="text-white text-3xl md:text-5xl py-10">Packages</header>
            <p className="text-lg md:text-xl" > Here are our combo packages, with estimation for your convenience </p>
            <div className="text-xl mt-10">
                <p>See packages for</p>
            </div>
            <div className="border inline-flex border-white mt-4 mb-20 w-80 md:w-96">
                <TypeBtn btn={0} >E-commerce</TypeBtn>
                <TypeBtn btn={1} className="border-x">Web</TypeBtn>
                <TypeBtn btn={2}>Custom</TypeBtn>
            </div>
            <div>
                <div className='flex flex-col gap-5 lg:gap-10'>
                    <div className='flex flex-col lg:flex-row gap-5 lg:gap-10'>
                        <ClientReview name="Krishna Patel" header="Incredible Results">
                            My e-commerce store was struggling for sales, our revenue has been low lately and it got me very worried. I decided to give these guys a shot and wow, the money we make recently is nothing short of mind-bending
                        </ClientReview>
                        <ClientReview name="John Stut" header="One of the best web agency">
                            Working with Joe's Studio was an absolute delight. They captured our vision flawlessly and transformed it into a captivating website that truly reflects our brand identity. Their creativity knows no limits!
                        </ClientReview>
                        <ClientReview name="Anna Nguyen" header="I love it">
                            Joe's Studio is a master at their craft. They grasped our ideas with precision and skillfully crafted a website that left us in awe. The end result perfectly represents our brand, and we couldn't be happier!
                        </ClientReview>
                    </div>
                    <div className='flex flex-col lg:flex-row gap-5 lg:gap-10'>
                        <ClientReview name="Jesse Lee" header="Surpassed My Expectations">
                            I had high expectations, but Joe's Studio surpassed them all. They are so dedicated to their craft, and it really shows in the website they designed for us. It's like a work of art!
                        </ClientReview>
                        <ClientReview name="Stefan K" header="A Pleasant Bunch">
                            Collaborating with Joe's Studio was a breeze. They were patient and attentive to our needs, and the end result was beyond what we had imagined. Their creative approach set our website apart from the rest.
                        </ClientReview>
                        <ClientReview name="Dave Smith" header="Great Post-Sales">
                            Their post-sales services are rock-solid. I have no needs for an in-house webmaster and my company's IT budget is now half the size. Good Stuff
                        </ClientReview>
                    </div>
                </div>
            </div>
        </div>
    )
    function TypeBtn(props) {
        return (
            <div className={"cursor-pointer text-sm md:text-base flex-1 p-2 md:p-4 border-white capitalize hover:bg-gray-700 transition duration-2000 backdrop-blur-xl " + props.className + " " + props.btn} onClick={() => { handleToggle(props.btn) }}>
                {props.children}
            </div>
        )
    }
    function Package(props) {
        return
    }
}

export default Packages