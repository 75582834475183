import { TypeAnimation } from "react-type-animation";
import Contact from "../Contact/Contact";

function IntroSection(props) {
    return (
            <div className=" min-h-screen overflow-x-hidden text-center py-48  ">
                <div className="mt-28 intro-section" class="text-white">
                    <div className="heading text-4xl lg:text-6xl">We Create & Design<br></br>Amazing <TypeAnimation
                        sequence={[
                            // Same substring at the start will only be typed out once, initially
                            'Web Brands',
                            1500,
                            'Websites',
                            1500,
                            'Webapps',
                            1500,
                            'Ecommerces',
                            1500,
                            'Mobile Apps',
                            1500,
                        ]}
                        wrapper="span"
                        speed={50}
                        style={{ display: 'inline-block' }}
                        repeat={Infinity}
                    /></div>
                </div>
            </div>
    );
}

export default IntroSection;