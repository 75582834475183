import { useEffect } from 'react';
import IntroSection from './IntroSection.js';
import StatementSection from './Statement/StatementSection.js';

function FrontPage() {
    useEffect(
        () => {
            document.title = 'Joe\'s Studio - Montreal No 1 Web Agency'
        }, []
    )
    return (
        <>
            <IntroSection ></IntroSection>
            <StatementSection></StatementSection>
        </>
    )
}
export default FrontPage;
