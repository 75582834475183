import fivestars from "../Media/5stars.png"

function ClientReview(props) {
    return (
        <div className="bg-gray-800 border-2 border-gray-700 p-5 text-start backdrop-blur-xl flex-1">
            <h1 className="text-3xl mb-4 font-light uppercase">{props.header}</h1>
            <div className="text-xl mb-4">
                <div className="review ml-4 my-0 w-10/12"> {props.children} </div>
            </div>
            <img src={fivestars} className="h-4 block mb-4" />
            <div className="text-xl">
                {props.name}
            </div>
        </div>
    )
}

export default ClientReview