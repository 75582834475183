import Contact from "./Contact";

function ContactPage() {
    return (
        <div className="mt-20">
            <Contact header="Contact Us"></Contact>
        </div>
    );
}

export default ContactPage; 